const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/8.png",
    text: "8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/9.png",
    text: "9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/10.png",
    text: "10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/11.png",
    text: "11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/12.png",
    text: "12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/13.png",
    text: "13",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/14.png",
    text: "14",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/15.png",
    text: "15",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/16.png",
    text: "16",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/17.png",
    text: "17",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/showflat/18.png",
    text: "18",
  },
];

export default data;
