import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Show Flat, Bangalore",
  address: "Bangalore, Karnataka",
  client: "",
  area: "4,000 Sq. Ft.",
  project: "Show Flat, Bangalore",
  category: "Interiors | Residential",
  status: "Completed",
  backlink: "/projects/interiors/",
};

export default class Projectshowflatbangalore extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Interior Projects | Show Flat, Bangalore"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the luxurious interiors and modern décor of a show flat spread over 4,000 sq. ft- designed for a leading residential developer."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
